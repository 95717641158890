.landing{
    position: relative;
    font-family: 'Exo 2',sans-serif;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    position: relative;
    /* background: url('../../assets/background/background.jpg'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}
.navBar{
    position: absolute;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    top: 3%;
    z-index: 10;
}
.navBar button{
    font-family: 'Exo 2',sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    height: 3rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    background: none;
    color: white;
    border: 2px solid white;
    border-radius: 1.5rem;
    padding: 0 1rem;
}
.navBar button:hover{
    background-color: white;
    color: #180d5b;
}
.glareImgTop{
    position: absolute;
    top: 10%;
    left: 40%;
    width: 18rem;
}
.glareImgBottom{
    position: absolute;
    bottom: -10%;
    right: -8%;
    width: 18rem;
}
.screenImg{
    position: absolute;
    top: 11%;
    right: 7%;
    width: 24rem;
}
.appLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5%;
    left: 5%;
    width: 10rem;
}
.appLogo img{
    width: 150%;
}
.backgroundImage{
    position: absolute;
    opacity: 0.2;
    width: 100%;
    height: 100vh;
}
.landingBox{
    display: flex;
    flex-direction: column;
    min-height: 50%;
    justify-content: space-evenly;
    z-index: 1;
    width: 40%;
    gap: 1rem;
}
.landingTitle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 1rem;
    text-transform: uppercase;
}
.landingFooter{
    position: absolute;
    bottom: 3%;
    left: 5%;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.landingFooter span{
    color: black;
    font-size: 1.3rem;
    font-weight: 700;
}
.centreName{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: #ffc929;
}
.centreName img{
    width: 6rem;
}
.developedByText{
    text-decoration: none;
    color: #ffc929;
}
.popup{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.5);
}
.popupContent{
    width: 70%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
.popupContent button{
    border: none;
    background-color: transparent;
}
.popupContent i{
    font-size: 1.5rem;
    color: white;
}
.popupContent embed{
    width: 100%;
    height: 100%;
}
.bgImg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 100vh;
    min-width: 100vw;
}
.note{
    color: rgba(255, 0, 0, 0.858);
    font-weight: 600;
    font-size: 0.9rem;
    margin: 0 5rem;
}
