.navbar{
    width: 100vw;
    display: flex;
    height: 6rem;
    color: white;
    justify-content: center;
    align-items: flex-end;
    background-color: black;
    border-bottom: 0.3rem solid #ffc929;
}
ul{
    margin: 0;
    padding: 0;
    list-style: none;
    width: 95%;
    display: flex;
    justify-content: space-between;
    height: 100%;
}
li{
    height: 100%;
    width: max-content;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
li span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
li img{
    margin: 0.5rem;
}
.selected{
    width: max-content;
    /* background-color: rgb(142 139 109); */
    color: black;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 700;
    position: relative;
}
.logoIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.logoIcon p{
    font-size: 1.5rem;
    font-weight: 700;
}
.appLogo{
    width: 10rem;
}
.kgpLogo{
    width: 2.5rem;
}
.selected i{
    margin-left: 0.5rem;
    color:white;
}
.popup{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.5);
}
.popupContent{
    width: 70%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
.popupContent button{
    border: none;
    background-color: transparent;
}
.popupContent i{
    font-size: 1.5rem;
    color: white;
}
.popupContent embed{
    width: 100%;
    height: 100%;
}
.help{
    color: white;
    position: absolute;
    right: 0;
    bottom: 10%;
    opacity: 0;
    transition: opacity 0.3s ease; /* Add transition for opacity */
}
.selected i{
    cursor: pointer;
}
.selected i:hover+.help{
    opacity: 1;
}
