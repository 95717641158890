.disclaimerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.disclaimerContent {
  position: relative;
  background: white;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.disclaimerContent p {
  margin-bottom: 20px;
}

.disclaimerContent button {
  padding: 10px 20px;
  background-color: #ffc929;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;

}
.exclamation {
  position: absolute;
  top: -20px;
  left: -25px;
  background-color: red;
  color: white;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.disclaimerContent button:hover {
  background-color: #ffb700;
}
