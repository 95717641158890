.invertOption{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 30vh;
    background-color: black;
    border-radius: 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.heading{
    font-size: 1.5rem;
}
.buttons{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;
}
.buttons button{
    cursor: pointer;
    border: none;
    width: 5rem;
    height: 2.5rem;
    border-radius: 1rem;
    font-size: 1.5rem;
    background-color: #ffc929;
}