.contactus{
    background-color: #180d5b;
    background: linear-gradient(to right,#275f8b,#180d5b);
    width: 100vw;
    height: max-content;
    min-height: 100vh;
    padding-top: 8rem;
}
.contactus::-webkit-scrollbar{
    display: none;
}
.info{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    height: max-content;
}
.principle,.team{
    width: 100%;
}
.title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    width: 100%;
    background-color: white;
    color: #180d5b;
    padding: 0.2rem 0;
    margin: 1.5rem 0;
}
.info img{
    width: 10rem;
    border-radius: 5rem;
}
.principleContent{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    padding: 2rem 0;
}
.team{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.teamInfo{
    display: flex;
    max-width: 100%;
    align-items: center;
    justify-content: space-around;
}
.member{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    gap: 1rem;
    padding: 1rem;
    border: 2px solid white;
    border-radius: 1rem;
    margin: 1rem;
}
.member h1{
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
}
.member p{
    padding: 0 1rem;    
    font-size: 1rem;
    font-weight: 500;
    text-align: center;

}
.member a{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
    color: white;
}
.navBar{
    position: absolute;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    top: 3%;
    z-index: 10;
}
.navBar button{
    font-family: 'Exo 2',sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    height: 3rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    background: none;
    color: white;
    border: 2px solid white;
    border-radius: 1.5rem;
    padding: 0 1rem;
}
.navBar button:hover{
    background-color: white;
    color: #180d5b;
}
.appLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5%;
    left: 5%;
    width: 10rem;
}
.appLogo img{
    width: 150%;
}
.landingBox{
    position: relative;
    left: 5%;
    display: flex;
    flex-direction: column;
    max-height: 50%;
    justify-content: space-evenly;
    z-index: 1;
    width: 95%;
    gap: 2rem;
    margin-top: 2rem;
}
.landingTitle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 1rem;
    text-transform: uppercase;
}
.landingFooter{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.landingFooter span{
    color: black;
    font-size: 1.3rem;
    font-weight: 700;
}
.centreName{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: #ffc929;
}
.centreName img{
    width: 6rem;
}
.developedByText{
    width: 100%;
    color: #ffc929;
}
.popup{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.5);
}
.popupContent{
    width: 70%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
.popupContent button{
    border: none;
    background-color: transparent;
}
.popupContent i{
    font-size: 1.5rem;
    color: white;
}
.popupContent embed{
    width: 100%;
    height: 100%;
}

