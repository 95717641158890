.axis{
    user-select: none;
    position: relative;
    z-index: 10;
    height: 100%;
    color: yellow;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}
.tick{
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.tick p{
    font-size: 10px;
    margin: 0;
    padding: 0;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
}
.dash{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
}
.bigDash {
    width: 100%;
    height: 60%; /* Adjust the height of the bigger dash as needed */
    border-right: 2px solid white; /* Add styling for the bigger dash */
    /* padding-right: 5px; Adjust the spacing to separate the number from the dash */
}

.smallDash {
    width: 100%;
    height: 20%; /* Adjust the height of the smaller dash as needed */
    border-right: 1px solid white; /* Add styling for the smaller dash */
}
.axisDataType{
    position: absolute;
    color: #201e1e;
    font-weight: 700;
    left: 1%;
    top: 0;
    margin: 0;
    padding: 0;
}