.completeYAxis{
    position: relative;
    z-index: 10;
    width: 10vw;
    max-width: 2rem;
    height: 100%;
    background-color: blue;
}
.axis{
    position: absolute;
    z-index: 10;
    width: 10vw;
    max-width: 2rem;
    background-color: blue;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.tick{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tick p{
    /* transform: rotate(90deg); */
    font-size: 10px;
    padding: 0;
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    width: 20%;
}
.dash{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
}
.bigDash {
    width: 60%;
    height: 60%; /* Adjust the height of the bigger dash as needed */
    border-bottom: 2px solid white; /* Add styling for the bigger dash */
    /* padding-right: 5px; Adjust the spacing to separate the number from the dash */
}

.smallDash {
    width: 20%;
    height: 60%; /* Adjust the height of the smaller dash as needed */
    border-bottom: 1px solid white; /* Add styling for the smaller dash */
}
.axisDataType{
    position: absolute;
    color: #ffc929;
    font-weight: 700;
    left: 10%;
    top: 5%;
    margin: 0;
    padding: 0;
}