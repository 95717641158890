.loginTitle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 1rem;
    text-transform: uppercase;
}
.warning{
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
}
h1{
    margin: 0;
}
span{
    margin: 0;
}
.loginSubtitle{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
}
.mode{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.mode span{
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: white;
    color: black;
    width: 50%;
    padding: 0.5rem 1rem;
    height: 3rem;
    border-radius: 0.5rem;
    text-align: center;
    cursor: pointer;
}
.loginForm{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    justify-content: space-evenly;
    min-width: 60%;
    min-height: 20rem;
}
.loginForm div{
    display: flex;
    width: 100%;
    align-items: center;
}
.loginInput{
    justify-content: space-between;
    font-size: 2rem;
    font-weight: 700;
    gap: 5rem;
}
.loginInput input{
    border: none;
    height: 2.5rem;
    width: 100%;
    padding: 0 1rem;
    font-size: 1.5rem;
    font-family: 'Exo 2',sans-serif;
    border-radius: 0.5rem;
    background-color: rgb(200, 200, 200);
}
.loginInput input::placeholder{
    font-size: 1.3rem;
    font-weight: 500;
    font-family: 'Exo 2',sans-serif;
    color: black;
}
.loginInput input:focus{
    outline: none;
}
.signup{
    font-size: 1.3rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: -2rem;
}
.loginButton,.signup{
    justify-content: center;
}
.signup span{
    text-decoration: underline;
    cursor: pointer;
}
.signup a{
    text-decoration: none;
    color: #ffc929;
    text-decoration: underline;
}
.loginButton button{
    font-family: 'Exo 2',sans-serif;
    font-weight: 700;
    width: 100%;
    text-transform: uppercase;
    border: none;
    height: 3rem;
    font-size: 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    background-color: #180d5b;
    border: 1px solid white;
    color: white;
}
.loginButton button:hover{
    background-color: black;
    color: #ffc929;
}
