.defectBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    background-color: #201e1e;
    border: 1px solid rgba(255, 255, 255, 0.311);
    border-radius: 0.5rem;
    padding: 0 1rem;
    width: max-content;
    min-width: 12rem;
    min-height: 12rem;
}
.title{
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
}
.posVal{
    color: #ffc929;
    font-weight: 700;
    font-size: 1.2rem;
}
.measuredData{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}