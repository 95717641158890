.mainLanding{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 5rem;
    min-height: 20rem;
}
h1,h2,p,button{
    padding: 0;
    margin: 0;
}
a{
    text-decoration: none;
}
.subtitle{
    color: #ffc929;
}
.info{
    font-size: 1.3rem;
    font-weight: 500;
    color: white;
}
.loginButton{
    margin-top: 1rem;
    padding: 0.5rem 1.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    background-color: #180d5b;
    border: 1px solid white;
    color: white;
    border-radius: 5rem;
    cursor: pointer;
}
.loginButton:hover{
    background-color: black;
    color: #ffc929;
}

