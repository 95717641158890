.process{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 8rem;
    background-color: black;
}
.importAndSave{
    width: max-content;
    height: 100%;
    color:white;
    border-right: 0.5px solid rgba(255, 255, 255, 0.648);
    background-color: #201e1e;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.3rem;
    justify-content: space-evenly;
}
.importAndSave p{
    padding: 0;
    margin: 0;
}
.fileAccess{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    gap: 0.5rem;
}
.accessButtons{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-evenly;
}

.accessButtons p{
    font-size: 0.8rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
}
.accessButtons button{
    width: 3rem;
    height: 3rem;
    border: none;
    border-radius: 0.5rem;
    color: white;
    background-color: transparent;
    transition: all 0.3s;
    cursor: pointer;
}
.accessButtons button:hover{
    color: black;
    background-color: #ffc929;
}

.zoom{
    width: 16vw;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.zoomButtons{
    width: 40%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
}
.zoomButtons button{
    width: 3.5rem;
    height: 3.5rem;
    border: none;
    border-radius: 0.5rem;
    color: white;
    background-color: transparent;
    transition: all 0.3s;
    cursor: pointer;
}
.zoomButtons button:hover{
    color: black;
    background-color: #ffc929;
}
.zoomButtons p{
    height: 1rem;
    padding: 0;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
}
.zoomButtons svg,.accessButtons svg{
    width: 1.5rem;
}
.zoomButtons:first-child svg{
    fill:red;
}
.functionalities{
    display: flex;
    align-items: center;
    height: 100%;
    gap: 1rem;
}
.functionalities button{
    width: max-content !important;
    padding: 0 0.3rem;
    border: 1px solid #201e1e !important;
}
.processButton{
    width: 4rem;
    height: 4rem;
    border: 1px solid rgba(255, 255, 255, 0.235);
    border-radius: 0.5rem;
    color: white;
    background-color: #201e1e;
    transition: all 0.3s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.8rem;
}
.processButton:hover{
    color: black;
    border: none;
    background-color: #ffc929;
}
.dataBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    background-color: #201e1e;
    border: 1px solid rgba(255, 255, 255, 0.311);
    border-radius: 0.5rem;
    padding: 0 1rem;
    width: max-content;
    min-width: 6rem;
    height: 80%;
}
.title{
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
}
.posData{
    position: relative;
    display: flex;
    min-width: 110%;
    width: max-content;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0.3rem;
    border-top: 1px solid white;
}
.dataBox button{
    font-size: 0.8rem;
    width: 80%;
    min-height: 1rem;
    border: none;
    background-color: white;
    font-weight: 600;
    transition: all 0.2s;
}
.dataBox button:hover{
    background-color: #ffc929;
}
.posVal{
    color: #ffc929;
    font-weight: 700;
    font-size: 1rem;
}
span{
    padding: 0;
    margin: 0;
}
p{
    padding: 0;
    margin: 0;
}
.measuredData{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap:0.5rem;
}
.measuredData button{
    width: 3rem;
    font-size: 0.8rem;
}
.disableMeasure:hover{
    background-color: white;
}
.measure:hover{
    background-color: #ffc929;
    color: rgb(255, 0, 0);

}
.uploadFile{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 40rem;
}
.chooseFile{
    border-radius: 1rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #201e1e;
    color: #ffc929;
    width: 40rem;
    min-height: 10rem;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: space-evenly;
    z-index: 1000;
    border: 2px solid #ffc929;
}
.uploadedFiles{
    position: absolute;
    min-width: 40rem;
    min-height: 18rem;
    max-height: 18rem;
    padding: 1rem 0;
    background-color:#201e1e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    border-radius: 1rem;
    border: 2px solid #ffc929;
}
.uploadedFiles td{
    text-align: center;
    color: #201e1e;
}
.uploadedFilesWrapper{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#201e1e9b;
    width: 100vw;
    height: 100vh;

}
.tableWrapper thead{
    position: sticky;
    height: 1rem;
    background-color: #201e1e;
    top: 0;
}
.tableWrapper{
    min-width: 38rem;
    max-height: 13rem;
    overflow-y: auto;
}
.tableWrapper table{
    width: 100%;
    
}
.uploadedFiles tbody{
    max-height: 18rem;
    overflow-y: auto;
}

.importFeather,.importExcel{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 1.5rem;
}
.importFeather p,.importExcel p{
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
}
.importFeather{
    border-right: 2px solid #ffc929;
    padding-right: 1rem;
}
.uploadFile input{
    display: flex;
    align-items: center;
    justify-content: center;
}
.chooseFile button{
    cursor: pointer;
    border: none;
    min-width: 5rem;
    height: 2.5rem;
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: #201e1e;
    background-color: #ffc929;
    padding: 0 0.5rem;
}
.chooseFile svg{
    position: absolute;
    width: 1rem;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
}
.dropdown{
    position: absolute;
    top: 50%;
    max-height: 20vh;
    overflow-y: auto;
    background-color: #ffd75d;
    border: none;
    width: 4rem;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
.dropdown::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome and Safari */
  }
.dropdown option{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    background-color: #ffd75d;
    padding: 0.2rem;
}
.dropdown option:hover{
    background-color: #201e1e;
    color: #ffc929;
}
.dropdown:focus{
    outline: none;
}
.dropdown::-ms-expand{
    display: none; /* Hide arrow in IE */
}
.dropdown::after {
    content: '\25BC'; /* Unicode for down arrow */
    position: absolute;
    top: calc(50% - 0.5em);
    right: 10px;
    pointer-events: none; /* Make arrow transparent to pointer events */
}
.scaleParamDataBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    background-color: #201e1e;
    border: 1px solid rgba(255, 255, 255, 0.311);
    border-radius: 0.5rem;
    padding: 0 1rem;
    width: max-content;
    min-width: 4rem;
    max-width: 12rem;
    height: 80%;
}
.scaleParamDataBox .title{
    font-size: 0.8rem;
}
.scaleData{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.scaleData input{
    max-width: 5rem;
    height: 1rem;
}
.scaleProcessButton{
    width: 40% !important;
    font-size: 0.8rem;
    width: 80%;
    min-height: 1rem;
    border: none;
    background-color: white;
    font-weight: 600;
    transition: all 0.2s;
}
.scaleProcessButton:hover{
    color: black;
    background-color: #ffc929;
}
.scalingParamDetail{
    position: absolute;
    width: max-content;
    top:100%;
    padding: 0 0.3rem;
    font-weight: 600;
    border-radius: 0.1rem;
    background-color: #ffc929;
    color: #201e1e;
    transition: opacity 0.3s ease;
    z-index: 1;
}
.scaleData p{
    font-size: 0.8rem;
    font-weight: 700;
    color: #ffc929;
    cursor: pointer;
}