@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700&family=Gabarito:wght@400;600;700&family=Onest:wght@400;500;600;700;800;900&family=Rowdies:wght@400;700&family=Russo+One&display=swap');
body::-webkit-scrollbar{
  display: none;
}
.sl-spinner3{
  height: 80%;
  width: 100vw;
  background-color: grey;
  position: absolute;
}
body::-webkit-scrollbar{
  display: none;
}