.graph{
    position:relative;
    display: flex;
    width: 40%;
    height: 100%;
}
.graph::-webkit-scrollbar {
    display: none;
}
.plot{
    background-color: #201e1e;
    position: absolute;
    left: 100%;
    transform: rotate(90deg);
    transform-origin: top left;
}