.plot {
  position: relative;
  width: 60%;
  height: 100%;
  overflow: hidden;
}

.image {
  position: relative;
  /* position: absolute; */
  /* left: 0; */
  width: 100%;
  height: 100%;
  user-select: none;
}

.parabola{
  position: absolute;
  width: 30%;
  height: 40%;
}

.verticalLine {
  position: absolute;
  border-left: 2px solid;
  height: 100%;
  bottom: 0;
  cursor: all-scroll;
  z-index: 1;
}
.horizontalLine {
  position: absolute;
  right: 0;
  width: 100%;
  border-top: 2px solid;
  cursor: all-scroll;
  z-index: 1;
}
.columnLabel {
  position: absolute;
  background-color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  bottom: 0;
  padding: 0;
  margin: 0;
  user-select: none;
}
.rowLabel {
  position: absolute;
  background-color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  right: 0;
  padding: 0;
  margin: 0;
  user-select: none;
}
.zoomRegion {
  position: absolute;
  border: 2px dashed #fff; /* Dashed border with white color */
  /* background-color: black; */
  background-color: rgba(
    255,
    255,
    255,
    0.3
  );
  /* ); Semi-transparent white background */
  /* pointer-events: none; Allow interactions with elements beneath the box */
}

.annotationRectangle {
  position: absolute;
  border: 2px solid #00f;
  background-color: transparent;
  pointer-events: none;
}

.annotationNameInput {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.annotationNameInput input {
  margin-bottom: 5px;
}
.rectangleName{
  position: relative;
  width: max-content;
  font-weight: 700;
  padding: 0 5px;
  background-color: white;
}
.columnLabel {
  position: absolute;
  background-color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  bottom: 0;
  padding: 0;
  margin: 0;
  user-select: none;
}