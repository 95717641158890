.questionsPage{
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: #201e1e85;
}
.questionSet{
  width: 60%;
  min-height: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #201e1e;
  border-radius: 1rem;
  padding: 1rem;
  overflow-y: auto;
  padding: 1.5rem 2rem;
  border: 1px solid #ffc929;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.questions h1{
  width: 100%;
  height: max-content;
  text-align: center;
  color: #ffc929;
}
.questions{
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.questions p{
  font-weight: 600;
  font-size: 1.2rem;
  color: #ffc929;
}
.question{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.question input{
  font-size: 1.2rem;
  width: 50%;
  height: 2rem;
  border: none;
  border-bottom: 1px solid #ffc929;
  background-color: transparent;
  color: #ffc929;
  cursor: pointer;
}
.question input:focus{
  outline: none;
}
.buttons{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
}
.buttons button{
  min-width: 5rem;
  min-height: 2rem;
  border: none;
  border-radius: 1rem;
  border: 1px solid #ffc929;
  background-color: #201e1e;
  color: #ffc929;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: all 0.2s;
}
.buttons button:hover{
  background-color: #ffc929;
  color: #201e1e;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: #ffc929;
  background-color: #ffc929;
}