.menuStyle{
    width: max-content;
    height: max-content;
    color: #ffc929;
    background-color: #201e1e;
    box-sizing: border-box;
    user-select: none;
}
.menuStyle ul{
    /* padding: 0.5rem 1rem; */
    display: flex;
    flex-direction: column;
    width: 100%;
}
.menuStyle ul li{
    cursor: pointer;
    font-weight: 700;
    padding: 0.5rem;
    border-radius: 0;
    width: 100%;
}
.menuStyle ul li:hover{
    background-color: #ffc929;
    color: #201e1e;
}