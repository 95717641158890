.belowWindow{
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.completeWindow{
    width: 100vw;
    display: flex;
    overflow: hidden;
}
.completeWindow::-webkit-scrollbar {
    display: none;
}
.plotWindow{
    height: 100%;
    display: flex;
    overflow-y: hidden;
}
.bottomAxis{
    width: 100%;
    height: 10vw;
    max-height: 2rem;
    display: flex;
}
.loader,.sl-spinner3{
    width: 100vw;
    background-color: grey;
    position: absolute;
}
